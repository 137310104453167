import { paymentAPI } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { filterABTestedPaymentMethods } from '../helpers/filterABTestedPaymentMethods'

/**
 * Returns the cached Payment Methods for the current country
 *
 * @example
 * const { data: paymentMethods } = await useMarketPaymentMethods()
 *
 */
export function useMarketPaymentMethods(options?: { immediate: boolean }) {
  const { market } = useMarketplace()

  const experiments = useExperiments()

  return useHttpFetch(paymentAPI.getMethods, {
    queryParams: {
      country_code: market.countryCode,
    },
    dedupe: 'defer',
    transform: (data) =>
      filterABTestedPaymentMethods(experiments, data.results),
    ...options,
  })
}
