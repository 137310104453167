import type { GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

/**
 * Filter a list of payment methods by removing those disabled for AB testing (remove Klarna or Affirm)
 */
export function filterABTestedPaymentMethods(
  experiments: Record<string, string>,
  paymentMethods: PaymentMethod[],
) {
  const filter = (methods: PaymentMethod[], experimentVariation: string) => {
    switch (experimentVariation) {
      case 'withoutClearpay':
        return methods.filter((method) => method.group !== 'clearpay')
      case 'withoutOney':
        return methods.filter((method) => method.group !== 'oney')
      case 'withoutKlarna':
        return methods.filter(
          (method) =>
            method.group !== 'klarna_pay_later' &&
            method.group !== 'klarna_slice_it',
        )
      default:
        return methods
    }
  }

  let filtered = paymentMethods
  filtered = filter(filtered, experiments['experiment.killOneyFr'])
  filtered = filter(filtered, experiments['experiment.enableClearpayUK'])
  filtered = filter(filtered, experiments['experiment.enableKlarnaIT'])

  return filtered
}

/**
 * Filter the paymentMethods property of each grade to remove methods that are disabled for AB testing.
 * @param {*} experiments
 * @param {Array<Object>} bestOffers
 * @returns
 */
export function filterBestOffersABTestedPaymentMethods(
  experiments: Record<string, string>,
  bestOffers: GetBestOffersResponse,
): GetBestOffersResponse {
  return bestOffers.map((offer) => {
    // BestOffer can technically returns null paymentMethods (when the offer is disabled)
    // It's not reflected in the type definition (probably because in practice it's not supposed to happen)
    // but we handle it here just to be safe
    if (!offer.paymentMethods) {
      return offer
    }

    return {
      ...offer,
      paymentMethods: filterABTestedPaymentMethods(
        experiments,
        offer.paymentMethods,
      ),
    }
  })
}
